@import "../../scss/variables";

$pagination-height: 56px;
$table-header-height: 85px;

.responsive-material-table {
  @media screen and (max-width: $medium) {
    padding: 15px 0;

    .admin-table-panel {
      background-color: initial;
      box-shadow: none;
      margin: 0;

      .mat-table {
        background-color: initial;
        padding: 0;
      }

      .mat-paginator {
        @include mat-elevation(2);
        margin-top: 15px;
      }
    }

    .mat-header-row {
      display: none;
    }

    .mat-row {
      display: block;
      overflow: hidden;
      height: auto;
      min-height: 0;
      margin: 0 15px 15px;
      padding: 0 15px;
      border-radius: 4px;
      @include mat-elevation(1);
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .mat-cell {
      margin: 16px 0;
      min-height: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:first-of-type {
        padding-left: 0;
      }
    }

    [data-label] {
      &:before {
        content: attr(data-label);
        display: inline;
        font-weight: normal;
        padding-right: 5px;
        color: $site-text-color-300;
      }
    }

    .hidden-on-mobile, .mat-column-select {
      display: none;
    }

    .mat-column-edit {
      position: absolute;
      top: 8px;
      right: 5px;
      margin: 0;
      padding: 0;
    }
  }
}